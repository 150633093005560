import type { PickRename } from "../types/global";
// @ts-expect-error autotypes instance file
import type __AT__ from "@autoTypes";

import * as React from "react";

import { useFetch, type UseFetchStateProps } from "./useFetch";
import { usePage } from "./usePage";
import { useSite } from "./useSite";

interface AiAnswersResponse {
	status: "ok" | "noResponse";
	response: string;
	error: string;
}

interface UseAiAnswersSetBodyAction {
	query: string;
	lang?: number;
	site?: number;
	format?: "lite" | "extended";
	minSimilarity?: number;
	priorities?: Partial<Record<__AT__.Templates, number>>;
	templates?: Array<__AT__.Templates>;
}

type UseAiAnswersSetQuery = UseAiAnswersSetBodyAction;

type UseAiAnswersReturn = [
	UseAiAnswersState,
	(setQuery: UseAiAnswersSetQuery) => void,
];

// The same state as useFetch but renaming data to query for this hook
type UseAiAnswersState = PickRename<
	UseFetchStateProps<AiAnswersResponse>,
	"data",
	"query"
>;

export function useAiAnswers(): UseAiAnswersReturn {
	const { publicApiUrl } = useSite();
	const { languageId } = usePage();
	// init with null to prevent call useFetch without a valid body.
	const [body, setBody] = React.useState<UseAiAnswersSetBodyAction | null>(
		null,
	);

	const setQuery = (options: UseAiAnswersSetQuery) => {
		const {
			query,
			lang = languageId as number, // this `as` remove "?" fro languageId coming from the hook
			site,
			templates,
			priorities,
			minSimilarity,
		} = options;
		setBody({
			query,
			lang,
			site,
			templates,
			priorities,
			minSimilarity,
		});
	};

	const { isError, isFirstFetch, isLoading, msg, data } =
		useFetch<AiAnswersResponse>(`${publicApiUrl}/ai/answers`, {
			method: "POST",
			body,
		});

	return [
		{
			isError,
			isFirstFetch,
			isLoading,
			msg,
			query: data,
		},
		setQuery,
	];
}

export {
	UseAiAnswersReturn,
	UseAiAnswersSetBodyAction,
	UseAiAnswersSetQuery,
	UseAiAnswersState,
	type AiAnswersResponse,
};
