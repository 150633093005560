import type { PickRename } from "../types/global";
// @ts-expect-error autotypes instance file
import type __AT__ from "@autoTypes";

import * as React from "react";

import { useFetch, type UseFetchStateProps } from "./useFetch";
import { usePage } from "./usePage";
import { useSite } from "./useSite";

// Api Response for AISearch hook
interface AiSearchResponse {
	page: number;
	itemsPerPage: number;
	totalItems: number;
	items: Array<{
		title: string;
		url: string;
		image: null;
		description: string;
		template: string;
		similarity: number;
		structuredData?: Record<string, unknown>;
	}>;
}

interface UseAiSearchSetBodyAction {
	query: string;
	lang?: number;
	site?: number;
	priorities?: Partial<Record<__AT__.Templates, number>>;
	templates?: Array<__AT__.Templates>;
	minSimilarity?: number;
	page?: number;
	itemsPerPage?: number;
	useStructuredData?: boolean;
}

type UseAiSearchSetQuery = UseAiSearchSetBodyAction;

type UseAiSearchReturn = [
	UseAiSearchState,
	(setQuery: UseAiSearchSetQuery) => void
];

// The same state as useFetch but renaming data to query for this hook
type UseAiSearchState = PickRename<
	UseFetchStateProps<AiSearchResponse>,
	"data",
	"query"
>;

export function useAiSearch(): UseAiSearchReturn {
	const { publicApiUrl } = useSite();
	const { languageId } = usePage();
	// init with null to prevent call useFetch without a valid body.
	const [body, setBody] = React.useState<UseAiSearchSetBodyAction | null>(null);

	const setQuery = (options: UseAiSearchSetQuery) => {
		const {
			query,
			lang = languageId as number, // this `as` remove "?" fro languageId coming from the hook
			site,
			templates,
			priorities,
			minSimilarity,
			page = 1,
			itemsPerPage = 10,
			useStructuredData = false,
		} = options;
		setBody({
			query,
			lang,
			site,
			templates,
			priorities,
			minSimilarity,
			page,
			itemsPerPage,
			useStructuredData,
		});
	};

	const { isError, isFirstFetch, isLoading, msg, data } =
		useFetch<AiSearchResponse>(`${publicApiUrl}/ai/search`, {
			method: "POST",
			body,
		});

	return [
		{
			isError,
			isFirstFetch,
			isLoading,
			msg,
			query: data,
		},
		setQuery,
	];
}

export {
	UseAiSearchReturn,
	UseAiSearchSetBodyAction,
	UseAiSearchSetQuery,
	UseAiSearchState,
	type AiSearchResponse,
};
